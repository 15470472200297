<template>
  
  <div>
    <div class="row mt-10">
      <div class="col-md-12 flex justify-between">
        <vs-button
            class="rounded-full text-white"
            color="primary"
            @click="toggleRoute()"
        >
            <feather-icon icon="UserPlusIcon" class="inline-block align-middle"></feather-icon>
            {{ this.$t('Nuevo Consumidor') }}
        </vs-button>
        <div class="inline-block row " style="vertical-align: middle; margin-left: 25px !important;">
          <div class="col-1 inline-block">
            <span>{{ this.$t('Buscar') }}: </span>
          </div>
          <div class="col-4 inline-block" style="vertical-align: middle;">
            <div class="border-2 h-12 border-solid rounded-full" style="background-color:white; border-radius: 9px;" @click="$refs['users-search'].focus()">
              <div class="flex h-full">
                  <feather-icon :icon="'SearchIcon'" class="mt-2 mb-2 ml-2" />
                  <input
                      @keyup.enter="searchAll()"
                      class="h-full w-full border border-none mr-5"
                      ref="users-search"
                      v-model="pagination.name"
                  />
                  <feather-icon @click="clearSearch()" v-show="pagination.name" icon="XCircleIcon" style="width: 18px; color: darkred;" class="mt-2 mb-2 mr-2" />
                </div>
            </div>
          </div>
          <div class="col-1 inline-block" style="margin-left: 25px !important;">
            <span>{{ this.$t('Ver') }}: </span>
          </div>
          <div class="col-4 inline-block" style="vertical-align: middle;">
            <div class="flex justify-end w-full">
              <!-- eslint-disable -->
              <vs-select v-model="pagination.page_size" class="w-32 inline-block">
                <vs-select-item :key="index" :value="item" :text="item" v-for="item,index in maxItemsVals" />
              </vs-select>
            </div>
            
          </div>
          <div class="col-2 inline-block" style="margin-left: 25px !important;">
            <vs-button :disabled="download_data.length == 0"
              style="background-color:#841910 !important"
              class="rounded-full rounded-ful"
              @click="exportData"
            >
                <feather-icon icon="DownloadIcon" class="align-middle"></feather-icon>
            </vs-button>
          </div>
          
        </div>
        
      </div>
    </div>
    <div v-if="!userForm" class="mt-10">
      <!-- <div class="flex justify-between">
        <h1 class="text-info">{{ this.$t('Usuarios Creados') }}</h1>
        <feather-icon
          @click="loadData()"
          icon="RefreshCwIcon"
          :class="{ 'animate-spin': loading }"
          class="cursor-pointer"
        />
      </div> -->

      <vs-table :max-items="maxItems" :data="all" :noDataText="this.$t( 'Datos no disponibles' ) ">
        <!-- encabezado de tabla -->
        <template slot="thead">
          <vs-th class="text-info bgf">{{ this.$t('Nombre') }}</vs-th>
          <vs-th class="text-info bgf">{{ this.$t('Correo Electrónico') }}</vs-th>
          <vs-th class="text-info bgf">{{ this.$t('Telefono') }}</vs-th>
          <vs-th class="text-info bgf">{{ this.$t('Clientes') }}</vs-th>
          <vs-th class="text-info bgf">{{ this.$t('Acciones') }}</vs-th>
        </template>
        <!-- cuerpo de tabla -->
        <template slot-scope="{data}">
          <template>
            <div style="min-height:20px"></div>
          </template>
          
          <vs-tr :data="tr" class="tr zoom" v-bind:key="indextr" v-for="(tr, indextr) in data">
            <!-- identification -->
            <!-- <vs-td :data="data[indextr].identification">
              <router-link to="/" class="text-info">{{ data[indextr].identification }}</router-link>
            </vs-td> -->

            <!-- nombre -->
            <vs-td style="padding-left:30px;" :data="data[indextr].name">
              <span>{{ data[indextr].name }}</span>
            </vs-td>

            <!-- email -->
            <vs-td :data="data[indextr].email">
              <span>{{ data[indextr].email }}</span>
            </vs-td>

            <!-- telefono -->
            <vs-td :data="data[indextr].phone">
              <span v-if="tr.phone">{{ data[indextr].phone }}</span>
              <span v-else>{{ data[indextr].cell_phone }}</span>
            </vs-td>

            <vs-td>
              <span>{{ data[indextr].assigned }}</span>
            </vs-td>

            <!-- Editar, eliminar, guardar -->
            <vs-td :data="data[indextr].id">
              <div class="inline-flex">
                <div class="mt-3">
                  <div v-if="data[indextr].status_id == 1"
                      style="background: #841A10; color: #ffffff;"
                      class="w-24 p-1 mb-1 rounded-lg text-center"
                    >
                      {{ $t('Activo') }}
                  </div>
                  <div v-if="data[indextr].status_id != 1"
                      style="background: #dee2e5; color: black;"
                      class="w-24 p-1 mb-1 rounded-lg text-center"
                  >
                      {{ $t('Inactivo') }}
                  </div>
                </div>
                <!-- <vs-switch
                  class="align-middle"
                  style="margin-top: 13px;"
                  :value="data[indextr].status_id === 1 ? true : false"
                  @click="changeStatus(data[indextr].id)"
                  :disabled="data[indextr].edit"
                /> -->
                <!-- editar -->
                  <vs-button color="transparent" @click="editUser( data[indextr].identification )">
                    <feather-icon icon="Edit2Icon" style="color:black !important" class="inline-block align-middle"></feather-icon>
                  </vs-button>
                
                <!-- inactivar -->
                  <vs-button v-if="data[indextr].status_id === 1" color="transparent" @click="activeDeleteDialog( data[indextr].id, data[indextr].email )" style="margin-left: -30px;">
                    <feather-icon icon="Trash2Icon" style="color:black !important" class="inline-block align-middle"></feather-icon>
                  </vs-button>

                <!-- activar -->
                  <vs-button v-if="data[indextr].status_id !== 1" color="transparent" @click="activeActiveDialog( data[indextr].id, data[indextr].email )" style="margin-left: -30px;">
                    <feather-icon icon="PlayCircleIcon" style="color:black !important" class="inline-block align-middle"></feather-icon>
                  </vs-button>
              </div>
            </vs-td>

          </vs-tr>
          <div style="margin-top:20px"> {{ $t('mostrando') }} {{ data.length }} {{ $t('de') }} {{ total }}</div>
        </template>
      </vs-table>
      <vs-pagination :total="pagination.total" class="mt-3" v-model="pagination.current"></vs-pagination>
      
    </div>

    <!-- modal eliminar -->
    <vs-prompt :active.sync="activePrompt" buttons-hidden>
      <div class="w-full">
        <vs-button  class="float-right" @click.native="closeDeleteDialog()" color="transparent" text-color="#666">
          <feather-icon icon='XIcon'  />
        </vs-button>
      </div>
      
      <div class="w-full flex justify-center mb-5">
        <feather-icon icon="UserXIcon" class="inline-block align-middle"></feather-icon>
      </div>
        
      <div class="text-center text-gray-400">
        <h3 class="mb-2">{{ this.$t('¿Estás seguro que deseas desactivar el consumidor seleccionado?') }}</h3>
        <h5> ( {{ name_delete }} )</h5>
        <br>
        <p>{{ this.$t('Al desactivar el consumidor, este no estará disponible para acceder, pero podrás activarlo en cualquier momento y editar las tiendas para acceder.') }}</p>

        <p><vs-button style="border: #e32113 solid 2px" class="mt-5 rounded-full"  :disabled="userId <= 0" @click="changeStatus(2)">{{ this.$t('Desactivar') }}</vs-button>
        <vs-button style="border: #e32113 solid 2px" class="mt-2 ml-5 rounded-full text-primary" @click.native="closeDeleteDialog()" color="#FFF">{{ this.$t('Cancelar') }}</vs-button></p>
      </div>
    </vs-prompt>

    <!-- modal eliminar -->
    <vs-prompt :active.sync="activePromptAct" buttons-hidden>
      <div class="w-full">
        <vs-button  class="float-right" @click.native="closeDeleteDialog()" color="transparent" text-color="#666">
          <feather-icon icon='XIcon'  />
        </vs-button>
      </div>
      
      <div class="w-full flex justify-center mb-5">
        <feather-icon icon="UserCheckIcon" class="inline-block align-middle"></feather-icon>
      </div>
        
      <div class="text-center text-gray-400">
        <h3 class="mb-2">{{ this.$t('¿Estás seguro que deseas activar el consumidor seleccionado?') }}</h3>
        <h5> ( {{ name_delete }} )</h5>
        <br>
        <p>{{ this.$t('Al activar el consumidor, se restablecerá el acceso del usuario a las tiendas agregadas.') }}</p>

        <p><vs-button style="border: #e32113 solid 2px" class="mt-5 rounded-full"  :disabled="userId <= 0" @click="changeStatus(1)">{{ this.$t('Activar') }}</vs-button>
        <vs-button style="border: #e32113 solid 2px" class="mt-2 ml-5 rounded-full text-primary" @click.native="closeDeleteDialog()" color="#FFF">{{ this.$t('Cancelar') }}</vs-button></p>
      </div>
    </vs-prompt>

  </div>
</template>

<script lang="ts">
import Vue             from 'vue';

import FeatherIcon     from '../../../components/FeatherIcon.vue';
import { Validation }  from '../../../plugins/validation.js';

export default Vue.extend({
  data : () => ({
    all             : [],
    users           : [],
    activePrompt    : false,
    activePromptAct : false,
    userId          : 0,
    userForm        : false,
    defaultCheckbox : true,
    validate        : new Validation(),
    sidebar         : false,
    userSelected    : { id : 0 },
    selected        : [],
    loading         : false,
    maxItems        : 20,
    total           : 0,
    name_delete     : '',
    pagination   : { total: 1, current: 1, page_size: 20, name: '' },
    maxItemsVals    : [5,10,15,20,25,30,35,40,45,50],
    newData: [],
    download_data    : []
  }),

  created() {
    this.$store.dispatch('updatePageTitle', {
      title: this.$t('Consumidores'),
      subtitle: this.$t('Adicione y gerencie los consumidores finales'),
    });
    this.$vs.loading();
    this.loadData();
    this.$vs.loading.close()
  },
  watch: {
    'pagination.current'() { this.findConsumers(false); },
    'pagination.page_size'() { this.findConsumers(false); }
  },
  methods: {
    async loadData() {
      await this.findConsumers(false);
      this.$vs.loading.close()
  
      await this.findConsumers(true);
    },
    async findConsumers(val) {
      this.loading = true
      let payload = {
          page      : this.pagination.current,
          page_size : this.pagination.page_size,
          name      : this.pagination.name,
          email     : this.pagination.name
      };
      if (val){
        payload.page_size = 1000
      }
      const results = await this.$store.dispatch('clients/getAllConsumers', payload);

      if( !results ){
        this.loading = false;
        this.$vs.loading.close()
      } else {
          this.loading = false;
          this.$vs.loading.close()
          if (val){
            this.download_data = results.data.results
          } else {
            this.all = results.data.results
            this.users = results.data.results
            this.total = results.data.total
            this.pagination.total = results.data.total_pages;
          }
      }
   
    },
    activeDeleteDialog( id, name ) {
      this.userId = id;
      this.name_delete = name
      this.activePrompt = true;
    },
    activeActiveDialog(id, email ){
      this.userId = id;
      this.name_delete = email
      this.activePromptAct = true;
    },
    closeDeleteDialog() {
      this.userId = 0;
      this.activePrompt = false; 
      this.activePromptAct = false;
    },
    async changeStatus(name) {
      this.$vs.loading();
      let payload = {
          status_id: name, 
          id: this.userId
      }
      await this.$store.dispatch( 'clients/putUserConsumers', payload );
      
      this.activePrompt = false; 
      this.activePromptAct = false;
      this.findConsumers(false);
      this.$vs.loading.close();
    },
    getStoreName( id ) {
      const store = this.stores.filter(store => store.id === id);

      if( store.length > 0 ) return store[0].name;
      else return '';

    },
    getUserTypeName( id ) {
      const role = this.userTypes.filter(userType => userType.id === id);
      if( role.length > 0 ) return role[0].name;

      return '';
    },
    editUser( id=0 ) {
      this.$router.push({ path : `/app/consumers/edit/${ id }` })
    },
    toggleRoute() {
        if( this.route === '/app/consumers/list' ) this.$router.push({ path : '/app/consumers/new' });
        else this.$router.push({ path : '/app/consumers/list' });
    },
    exportData() {
      let data = [];            

      this.$vs.loading();

      if( this.all.length === 0 ) {
        this.$vs.notify({
            title: this.$t('No hay datos en esta consulta'),
            text: '',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
        });
        return;
      }

      data = this.mapData( this.download_data );

      const headerVal   = [
          "identification",
          "name",
          "email",
          "phone",
          "c_clients",
          "status",
      ];
      const headerTitle = [
          this.$t("Identificacion"),
          this.$t("Nombre completo"),
          this.$t("Email"),
          this.$t("Telefono"),
          this.$t("Cant. Clientes"),
          this.$t("Estado")
      ];

      const cellAutoWidth = true;
      const self = this;
      
      self.exportData = data;
      
      import('../../../vendor/Export2Excel').then( excel => {
        const list = self.exportData;
        this.$vs.loading.close();
    
        const data = this.formatJson( headerVal, list );

        excel.export_json_to_excel({
            header: headerTitle,
            data,
            filename: "consumers",
            autoWidth: cellAutoWidth,
            bookType: "xlsx",
          });
            
        });
    },
    mapData( data=[] ) {            
      
      data.forEach( key => {
        let state = this.$t('Activo')
        if( key.status_id != 1 ){ 
          state = this.$t('Inactivo');}
        this.newData.push({
            identification : key.identification,
            name           : key.name,
            email          : key.email,
            phone          : key.phone,
            c_clients      : key.assigned,
            status         : state
        });
      });
      
      return this.newData;
        
    },

    formatJson( filterVal=[], jsonData=[] ) {
        return jsonData.map( v => filterVal.map( j => {
            return v[j];
        }) );
    },
    getTextStatus( statusId ) {
        if( statusId == 1 ){ return this.$t('Activo');}
        else { return this.$t('Inactivo');}
    },
    clearSearch(){
      this.pagination.name = '';
      this.findConsumers(false);
    },
    searchAll(){
      this.findConsumers(false);
      /* const l = e.toLowerCase()
      const temp = this.users.filter(function (d) {
        return (d.email.toLowerCase().indexOf(l) !== -1 || d.name.toLowerCase().indexOf(l) !== -1) || !l;
      });
      this.all = temp; */
    },
  }, 
  computed : {

    route() {
        return this.$route.path;
    },
    isEdit() {
      const usersEdit = this.users.filter( user => user.edit === true );
      return !!usersEdit.length;
    }
    
  },

  components: {
    FeatherIcon
  },
});
</script>
<style lang="scss">
.rounded-ful{
  border-radius: 15px !important;
  //background-color: white !important;
  border-width: 1px !important;
}

.vs-table-text{
  text-transform: uppercase;
}

/**.tr{
  border-bottom: #B9B9C3 solid 1px;
} */

.bgf{
  background-color: #F3F2F7!important;
  color: #636363 !important;
  text-transform: uppercase !important;
}

th.td-check {
  padding: 0!important;
  background-color: #F3F2F7!important;
}

.zoom:hover {
  //transform: scale(1.02) !important;
  //border:solid
}

.zoom {
  transition: transform .8s;
}

.vs-switch-primary.vs-switch-active {
  background: rgba(var(--vs-primary),1);
}

.feather-user-x {
  width: 50px;
  height: 50px;
}

.feather-user-check {
  width: 50px;
  height: 50px;
}

</style>

export class Validation {
    
    numberError         = '';
    influenceError      = '';
    identificationError = '';
    priceError          = '';
    nameError           = '';
    lastnameError       = '';
    codeError           = '';
    skuError            = '';
    descriptionError    = '';
    nitError            = '';
    positionNameError   = '';
    userTypeError       = '';
    departmentError     = '';
    cityError           = '';
    addressError        = '';
    storesError         = '';
    emailError          = '';
    email2Error         = '';
    passwordError       = '';
    password2Error      = '';
    cellphoneError      = '';
    phoneError          = '';
    whatsappError       = '';
    coordsError         = '';
    urlError            = '';

    constructor() {}

    validateNumber( number ) {
        if( !number ) {
            this.numberError = 'Ingrese un número';
            return false;
        }

        if( number < 0 ) {
            this.numberError = 'Ingrese un número';
            return false;
        }

        this.numberError = '';
        return true;
    }

    validateInfluence( number ) {
        if( number < 0 ) {
            this.influenceError = 'Ingrese un número';
            return false;
        }

        this.influenceError = '';
        return true;
    }

    validateIdentification( identification ) {
        if( identification.length < 7 ) {
            this.identificationError = 'Ingrese siete o más caracteres';
            return false;
        }

        this.identificationError = '';
        return true;
    }

    validatePrice( price=0 ) {
        price = parseFloat( price );
        
        if( !price && price !== 0 ) {
            this.priceError = 'Ingrese un monto válido';
            return false;

        } else if( price < 0 ) {
            this.priceError = 'Ingrese un monto superior a 0';
            return false;

        } else {
            this.priceError = '';
            return true;

        }

    }
    
    validateName( name ) {
        if( name.length <= 3 ) {
            this.nameError = 'Ingrese más de tres caracteres'; 
            return false;
        } else {
            this.nameError = '';
        }

        return true;
    }

    validateLastname( lastname ) {
        if( lastname.length <= 3 ) {
            this.lastnameError = 'Ingrese más de tres caracteres'; 
            return false;
        } else {
            this.lastnameError = '';
        }

        return true;
    }

    validateStoreCode( code ) {
        if( code.length <= 3 ) {
            this.codeError = 'Ingrese más de tres caracteres'; 
            return false;
        } else {
            this.codeError = '';
        }

        return true;
    }

    validateSKU( sku ) {
        if( sku.length <= 3 ) {
            this.skuError = 'Ingrese más de tres caracteres'; 
            return false;
        } else {
            this.skuError = '';
        }

        return true;
    }

    validateDescription( description ) {
        if( !description ) {
            this.descriptionError = 'Ingrese el campo solicitado'; 
            return false;

        } else if( description.length > 100 ) {
            this.descriptionError = 'Ingrese menos de cien caracteres'; 
            return false;

        } else if( description.length <= 3 ) {
            this.descriptionError = 'Ingrese más de tres caracteres'; 
            return false;

        } else {
            this.descriptionError = '';
            
        }

        return true;
    }

    validateDocType( documentTypeId ) {
        if( documentTypeId <= 0 ) {
            this.nitError = 'Seleccione un tipo de documento';
            return false;
        } else {
            this.nitError = '';
        }

        return true;
    }

    validateNit( nit ) {
        if( !nit ) {
            this.nitError = 'Ingrese al menos tres caracteres';
            return false;
        }

        if( nit.length <= 0 ) {
            this.nitError = 'Ingrese al menos tres caracteres';
            return false;
        } else {
            this.nitError = '';
        }

        return true;
    }

    validatePositionName( positionName ) {
        if( !positionName ) {
            this.positionNameError = 'Ingrese al menos tres caracteres';
            return false;

        }
        else if( positionName.length <= 2 ) {
            this.positionNameError = 'Ingrese al menos tres caracteres';
            return false;

        } else {
            this.positionNameError = '';

        }

        return true;
    }

    validateUserType( userTypeId ) {
        if( userTypeId < 1 ) {
            this.userTypeError = 'Seleccione un tipo de usuario';
            return false;
        } else {
            this.userTypeError = '';
        }

        return true;
    }

    validateDepartment( departmentId ) {
        if( departmentId < 1 ) {
            this.departmentError = 'Seleccione un departamento';
            return false;
        } else {
            this.departmentError = '';
        }

        return true;
    }

    validateCity( cityId ) {
        if( cityId < 1 ) {
            this.cityError = 'Seleccione una ciudad';
            return false;
        } else {
            this.cityError = '';
        }

        return true;
    }

    validateAddress( address ) {
        if( address.length <= 7 ) {
            this.addressError = 'Ingrese más de 7 caracteres';
            
            return false;
        } else {
            this.addressError = '';
        }

        return true;
    }

    validateStores( stores ) {
        if( stores.length <= 0 ) {
            this.storesError = 'Seleccione al menos una tienda';
            return false;
        } else {
            this.storesError = '';
        }

        return true;
    }

    validateEmail( email ) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        const isValidMail = re.test(String(email).toLowerCase());
        
        if( !isValidMail ) {
            this.emailError = 'Ingrese un correo electrónico válido';
        } else {
            this.emailError = '';
        }
        
        return isValidMail;
    }

    validateEmail2( email, emailConfirm ) {
        if( email !== emailConfirm ) {
            this.email2Error = 'Los correos electrónicos no coinciden';
            return false;
        } else {
            this.email2Error = '';
        }

        return true;
    }

    validatePassword( password ) {
        if ( password.length < 6 ) {
            this.passwordError = 'Ingrese al menos 6 carcateres';
            return false;
        }
        
        if (/[a-z]/g.test(password) === false) {
            this.passwordError = 'Su contraseña debe tener al menos una letra minúscula';
            return false;
        }

        if (/[A-Z]/g.test(password) === false) {
            this.passwordError = 'Su contraseña debe tener al menos una letra mayúscula';
            return false;
        }

        if (/[0-9]/g.test(password) === false) {
            this.passwordError = 'Su contraseña debe tener al menos un número';
            return false;
        }
        
        this.passwordError = '';
        return true;
    }

    validatePassword2( password, password2) {
        if( password !== password2 ) {
            this.password2Error = 'Las Contraseñas no coinciden';
            return false;
        }

        this.password2Error = '';

        return true;
    }

    validatePhone( phone ) {
        if (phone){
          if( phone.length < 6 ) {
              this.phoneError = 'Ingrese un número de teléfono fijo válido';
              return false;
          } 
        } else {
          this.phoneError = 'Ingrese un número de teléfono fijo válido';
          return false;
        }

        this.phoneError = '';
        return true;
    }

    validateCellphone( cellphone ) {
        if( cellphone.length !== 10 ) {
            this.cellphoneError = 'Ingrese un número de teléfono celular  válido';
            return false;
        }

        this.cellphoneError = '';
        return true;
    }

    validateWhatsapp( whatsapp ) {
        if( whatsapp.length < 6 ) {
            this.whatsappError = 'Ingrese un número de válido';
            return false;
        }

        this.whatsappError = '';
        return true;
    }

    validateCountryCode( code ) {
        if( !code ) {
            console.log('code');
            this.cellphoneError = 'Ingrese un código de area válido';
            return false;
        }

        this.cellphoneError = '';
        return true;
    }

    validateCoords( coords ) {
        const isValidCoords = coords.includes(',');

        if( !isValidCoords ){
            this.coordsError = 'Ingrese los valores separados por comas (,)';
            return false;   
        }

        this.coordsError = '';
        return true;
    }

    validateUrl( url='' ) {
        /* eslint-disable */
        const regExp = /(http|https|ftp|ftps)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?/

        if( !url.match( regExp ) ){
            this.urlError = 'Ingrese una URL con el formato http://example.com o similares';
            return false;

        } else {
            this.urlError = '';
            return true;

        }

    }

}
